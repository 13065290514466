import { Table } from "antd";
import React from "react";
import { MediaDetails } from "../../../api/media.service";

interface MediaModalFileDetailsProps {
  mediaDetails: MediaDetails | undefined;
}

export const MediaModalFileDetails: React.FC<MediaModalFileDetailsProps> = ({
  mediaDetails
}) => {
  const files = mediaDetails?.files || [];

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id"
    },
    {
      dataIndex: "url",
      key: "url",
      render: (value: string) => {
        return (
          <>
            <a href={value}>Download</a>
          </>
        );
      }
    },
    {
      title: "Filename",
      dataIndex: "filename",
      key: "filename"
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type"
    },
    {
      title: "Metadata",
      dataIndex: "metadata",
      key: "metadata",
      render: (value: any) => (
        <div style={{ overflow: "auto", maxHeight: "200px" }}>
          <pre>{JSON.stringify(value, null, 2)}</pre>
        </div>
      )
    }
  ];

  return mediaDetails ? (
    <>
      <Table
        dataSource={files.map((f) => {
          return { key: f.id, ...f };
        })}
        columns={columns}
      />
    </>
  ) : (
    <span>"Details missing"</span>
  );
};
