import React from "react";
import { AccountDetails } from "../../../api/account.service";
import styles from "./account-modal.module.scss";

interface AccountModalAccountDetailsProps {
  accountDetails: AccountDetails | undefined;
}

export const AccountModalAccountDetails: React.FC<AccountModalAccountDetailsProps> =
  ({ accountDetails }) => {
    return accountDetails ? (
      <>
        {accountDetails.name} <br />
        Account Id: {accountDetails.accountId}
        <br />
        Account type: {accountDetails.accountType.type}
        <br />
        Credit - free seconds: {accountDetails.creditFreeSeconds}
        <br />
        Credit - paid seconds: {accountDetails.creditPaidSeconds}
        <br />
        Credit - payg seconds: {accountDetails.creditPaygSeconds}
        <br />
        Credit - extra seconds: {accountDetails.creditExtraSeconds}
        <br />
        Total free seconds used: {accountDetails.totalFreeSecondsUsed}
        <br />
        Total paid seconds used: {accountDetails.totalPaidSecondsUsed}
        <br />
        Team capacity: {accountDetails.teamCapacity}
        <br />
        Billing:
        <div className={styles.scrollable}>
          <pre>{JSON.stringify(accountDetails.billing, null, 2)}</pre>
        </div>
        <br />
        Settings:{" "}
        <div className={styles.scrollable}>
          <pre>{JSON.stringify(accountDetails.settings, null, 2)}</pre>
        </div>
        <br />
        Is Pay as you go: {accountDetails.isPayg ? "YES" : "NO"}
        <br />
        Created at: {accountDetails.createdAt}
        <br />
        Updated at: {accountDetails.updatedAt}
      </>
    ) : (
      <span>"Details missing"</span>
    );
  };
