import React from "react";
import { JobDetails } from "../../../api/job.service";
import { Collapse } from "antd";

interface JobDetailsProps {
  details: JobDetails | undefined;
}

const { Panel } = Collapse;

export const JobDetailsComponent: React.FC<JobDetailsProps> = ({ details }) => {
  if (!details) return null;

  const inputPanels = details.inputs.map((p, i) => {
    return (
      <Panel header={`${p.fileId} - ${p.filename}`} key={i}>
        <p>type: {p.type}</p>
        <p>filename: {p.filename}</p>
        <p>extension: {p.extension}</p>
        <p>s3Uri: {p.s3Uri}</p>
        <p>sizeBytes: {p.sizeBytes}</p>
        <p>durationSeconds: {p?.durationSeconds}</p>
        {p.metadata && (
          <div>
            metadata:
            <div style={{ overflow: "auto", maxHeight: "200px" }}>
              <pre>{JSON.stringify(p.metadata, null, 2)}</pre>
            </div>
          </div>
        )}
        <p>uploadedAt: {p.uploadedAt}</p>
      </Panel>
    );
  });

  const outputPanels = details.outputs.map((p, i) => {
    return (
      <Panel header={`${p.fileId} - ${p.filename}`} key={i}>
        <p>type: {p.type}</p>
        <p>filename: {p.filename}</p>
        <p>extension: {p.extension}</p>
        <p>s3Uri: {p.s3Uri}</p>
        <p>sizeBytes: {p.sizeBytes}</p>
        <p>durationSeconds: {p?.durationSeconds}</p>
        {p.metadata && (
          <div>
            metadata:
            <div style={{ overflow: "auto", maxHeight: "200px" }}>
              <pre>{JSON.stringify(p.metadata, null, 2)}</pre>
            </div>
          </div>
        )}
        <p>uploadedAt: {p.uploadedAt}</p>
      </Panel>
    );
  });

  return (
    <>
      <p>media name: {details.media}</p>
      <p>id: {details.id}</p>
      <p>accountId: {details.accountId}</p>
      <p>mediaId: {details.mediaId}</p>
      <p>type: {details.type}</p>
      <p>status: {details.status}</p>
      <p>startDate: {details.startDate}</p>
      <p>endDate: {details?.endDate}</p>
      <p>message: {details.message}</p>
      <p>Inputs</p>
      <Collapse>{inputPanels}</Collapse>
      <p>Outputs</p>
      <Collapse>{outputPanels}</Collapse>
    </>
  );
};
