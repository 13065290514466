import React from "react";
import { UserDetailsDatabase } from "../../../api/user.service";
import { Tag } from "antd";
import { MediaListDetails } from "../media-details/media-list-details";

interface UserDatabaseDetailsProps {
  details: UserDetailsDatabase;
}

export const UserDatabaseDetails: React.FC<UserDatabaseDetailsProps> = ({
  details
}) => {
  return (
    <>
      <p>username: {details.username}</p>
      <p>
        groups:{" "}
        {details.groups.map((g, i) => {
          return (
            <Tag color="blue" key={i}>
              {g}
            </Tag>
          );
        })}
      </p>
      <p>status: {details.status}</p>
      <p>givenName: {details.givenName}</p>
      <p>familyName: {details.familyName}</p>
      <p>email: {details.email}</p>
      <p>emailVerified: {details.emailVerified}</p>
      <p>phoneNumber: {details.phoneNumber}</p>
      <p>phoneNumberVerified: {details.phoneNumberVerified}</p>
      <p>sub: {details.sub}</p>
      <p>referrer: {details.referrer}</p>
      <p>partner: {details.partner}</p>
      <p>createdAt: {details.createdAt}</p>
      <p>updatedAt: {details.updatedAt}</p>
      <div>
        settings:{" "}
        <div style={{ overflow: "auto", maxHeight: "200px" }}>
          <pre>{JSON.stringify(details.settings, null, 2)}</pre>
        </div>
      </div>
      <p>excludeReporting: {details.excludeReporting}</p>
      <div>
        accounts:{" "}
        <div style={{ overflow: "auto", maxHeight: "200px" }}>
          <pre>{JSON.stringify(details.accounts, null, 2)}</pre>
        </div>
      </div>
      <MediaListDetails mediaList={details.mediaList} />
    </>
  );
};
