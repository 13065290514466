import axios from "axios";
import config from "../config";
import { getAccessToken } from "./auth.service";
import { mediaStore } from "../state/media/media.store";
import { notification } from "antd";
import { EN } from "../assets/i18n/en";
import { mediaQuery } from "../state/media/media.query";
import { DateTime } from "luxon";
import { orderStore } from "../state/order/order.store";
import { MediaConfig } from "@getsubly/common";

const baseURL = `${config.apiUrl}/api/v1/admin`;

export interface MediaListItem {
  accountId: string;
  mediaId: string;
  name: string;
  sizeBytes: number;
  extension: string;
  durationSeconds: number;
  height: number;
  width: number;
  createdBy: string;
  createdAt: Date;
  updatedAt: Date;
  downloadedAt?: Date;
  burnedAt?: Date;
  language: string;
}

export const fetchMediaList = async () => {
  try {
    const params = mediaQuery.mediaListParams;
    if (!(params.filter && params.search)) {
      mediaStore.updateList({
        loading: false,
        mediaList: []
      });
      return;
    }

    mediaStore.updateList({
      loading: true,
      mediaList: []
    });

    const { data } = await axios.get<{
      message: string;
      mediaList: MediaListItem[];
    }>(`${baseURL}/media?filter=${params.filter}&search=${params.search}`, {
      headers: {
        "x-access-token": await getAccessToken()
      }
    });

    mediaStore.updateList({
      loading: false,
      mediaList: data.mediaList
    });
  } catch (error) {
    handleError(error);
  } finally {
    mediaStore.updateList({
      loading: false
    });
  }
};

export interface MediaDetails {
  accountId: string;
  mediaId: string;
  name: string;
  sizeBytes: number;
  extension: string;
  durationSeconds: number;
  height: number;
  width: number;
  createdBy: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  downloadedAt: DateTime;
  burnedAt: DateTime;
  language: string;
  jobs: MediaJob[];
  files: MediaFile[];
  logs: MediaLog[];
  sharedUsers: MediaSharedUser[];
  status: string;
  mediaConfig: MediaConfig | string;
}

export interface MediaJob {
  id: string;
  type: string;
  status: string;
  startDate: DateTime;
  endDate?: DateTime;
  message: string;
}

export interface MediaFile {
  id: string;
  type: string;
  filename: string;
  url: string;
  metadata: any;
  uploadedAt: DateTime;
}

export interface MediaSharedUser {
  shareId: string;
  email: string;
  name?: string;
  permission: MediaSharePermission;
}

export enum MediaSharePermission {
  View = "view",
  Edit = "edit"
}

export interface MediaLog {
  action: string;
  type: string;
  resourceId: string;
  metadata: any;
  createdAt: DateTime;
}

export const fetchMediaDetails = async (mediaId: string) => {
  try {
    mediaStore.updateDetails({
      loading: true,
      mediaDetails: undefined
    });
    const { data } = await axios.get<{
      message: string;
      mediaDetails: MediaDetails;
    }>(`${baseURL}/media/${mediaId}`, {
      headers: {
        "x-access-token": await getAccessToken()
      }
    });
    console.log("data:", data);
    mediaStore.updateDetails({
      loading: false,
      mediaDetails: data.mediaDetails
    });
  } catch (error) {
    handleError(error);
    mediaStore.updateDetails({
      loading: false
    });
  }
};

export const replaceMediaFile = async (
  mediaId: string,
  fileId: string,
  body: string
) => {
  try {
    orderStore.update({ isReplacingFile: true });

    await axios.put<{
      message: string;
    }>(
      `${baseURL}/${mediaId}/replace-file/${fileId}`,
      {
        content: body
      },
      { headers: { "x-access-token": await getAccessToken() } }
    );

    orderStore.update({ isReplacingFile: false });
  } catch (error) {
    handleError(error);
    orderStore.update({
      isReplacingFile: false
    });
  }
};

export const downloadArchive = async (mediaId: string) => {
  try {
    const { data } = await axios.get<{ url: string }>(
      `${baseURL}/media/${mediaId}/download/archive`,
      {
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );
    return data.url;
  } catch (error) {
    handleError(error);
  }
};

export interface MediaShareParams {
  email: string;
  permission: MediaSharePermission;
}

export const addMediaShare = async (
  mediaId: string,
  params: MediaShareParams
) => {
  await axios.post<{
    message: string;
  }>(`${baseURL}/media-share/${mediaId}`, params, {
    headers: {
      "x-access-token": await getAccessToken()
    }
  });
};

export const deleteMediaShare = async (mediaShareId: string) => {
  try {
    await axios.delete<{
      message: string;
    }>(`${baseURL}/media-share/${mediaShareId}`, {
      headers: {
        "x-access-token": await getAccessToken()
      }
    });

    notification.success({
      message: `Successfully deleted media share`,
      duration: 10
    });
  } catch (error) {
    handleError(error);
  }
};

// Private functions

const handleError = (e: any) => {
  console.error(e);

  notification.error({
    message: "Something went wrong",
    description: e?.message || EN.error.defaultMessage,
    duration: 10
  });
};
